#accordion {
    border-bottom: 1px solid #ddd;
}

#accordion .panel, #accordion .panel-heading {
    border-radius: 0;
    border: none;
}

#accordion .panel-body {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

#accordion .panel+.panel {
    margin-top: 0;
}

#accordion .accordion-toggle {
    color: #fff;
    font-weight: bold;
    padding-left: 20px;
    display: block;
}

#accordion .panel-heading a:hover, #accordion .panel-heading a:focus {
    text-decoration: none;
}

#accordion .description {
    background-color: #000000;
}

#accordion .example {
    background-color: #6E6D6E;
}

#accordion .content {
    background-color: #73A8AC;
}

#accordion .instructions {
    background-color: #376C81;
}

#accordion .activity {
    background-color: #8E3C1F;
}

#accordion .register {
    background-color: #DA6A31;
}

#accordion .evaluation {
    background-color: #ABA749;
}

#accordion .feedback {
    background-color: #788135;
}

.panel-default > .panel-heading {
    background: #fff;
    font-weight: 400;
    position: relative;
    color: #696969;
    border-bottom: none;
}

.panel-heading .collapse-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -10px;
}

.panel-heading .collapse-icon:after {
    font-family: FontAwesome;
    content: "\f0d7";
}

.panel-heading .collapsed .collapse-icon:after {
    content: "\f0da" !important;
}

.accordion-toggle {
    text-decoration: none;
}

.flag-button img {
    width: 18px;
    vertical-align: -2px;
}