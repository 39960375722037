/* Font face */
@font-face {
    font-family: "OswaldLight";
    font-style: normal;
    font-weight: normal;
    src:
        url("/font/Oswald-Light.ttf") format("truetype");
}

@font-face {
    font-family: "OswaldRegular";
    font-style: normal;
    font-weight: normal;
    src:
    url("/font/Oswald-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "OswaldBold";
    font-style: normal;
    font-weight: normal;
    src:
    url("/font/Oswald-Bold.ttf") format("truetype");
}

/* Base */
body {
    padding-top: 110px;
    line-height: 20px !important;
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-size: 16px;
}

.nowrap {
    white-space: nowrap;
}

.clear {
    clear: both;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .margin-bot {
    margin-bottom: 5px;
}

label {
    margin-left: 5px;
}

/* Header */
header {
    left: 0;
    margin-left: 0;
    position: fixed;
    top: 0px;
    z-index: 1030;
    width: 100%;
}

.nav-fixed {
    background: #4A494A;
}

.header-top-wrap .container {
    padding-bottom: 15px;
    padding-top: 15px;
}

/* Top bar */
#top-bar {
    position: fixed;
    top: 68px;
    width: 100%;
    z-index: 1029;
    font-size: 13px;
    background: #6F6E6F;
    border-bottom: 2px #E0745A solid;
    height: auto;
}

/* Navbar top */
.nav-toogle {
    margin: 6px 0px 0px 0px;
    line-height: 0.9;
}

.current_page_item {
    background: none !important;
}

.current_page_item > a {
    background: #E0745A !important;
    border-radius: 3px !important;
}

nav#top-bar-nav {
    border-right: 0;
    color: #FFF;
    font-size: 18px;
    width: 100%;
    margin-right: 0;
    line-height: 22px;
}

#top-bar #top-bar-nav, #top-bar .top-bar-text {
    padding: 9px 0 9px 0;
}

nav#top-bar-nav ul li a {
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-size: 13px;
}

nav#top-bar-nav div {
    display: table;
    width: 100%;
}

nav#top-bar-nav div div {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

nav#top-bar-nav div div:first-child {
    text-align: left;
}

nav#top-bar-nav div div:last-child {
    text-align: right;
}

nav#top-bar-nav ul li:after {
    content: "";
}

/* Navbar main */
#main-nav ul li a {
    font-family: "OswaldRegular","OpenSansSemibold",Arial,Helvetica,sans-serif;
    color: #FFF;
    font-size: 18px;
    text-transform: none;
    text-decoration: none;
    border-radius: 3px;
}

#main-nav li:hover > a {
    background: #E0745A;
}

#main-nav li > ul li a {
    color: #FFF;
}

#main-nav ul ul > li:hover a {
    color: #FFF;
    background: #E0745A;
}

#main-nav li:hover > a, #main-nav ul ul > li:hover > a {
    background: #E0745A !important;
    color: #FFF !important;
    border-radius: 3px;
}

#main-nav ul ul > li {
    background: #4A494A;
}

#main-nav ul ul > li a {
    text-transform: none;
}

#main-nav ul ul > li > a:before {
    color: #FFF !important;
}

.navbar-collapse {
    border: 0;
    box-shadow: none;
}

/* Navbar bottom */
.footer-bottom {
    font-size: 14px;
    background: #6F6E6F;
    color: #FFF;
}

nav#footer-bottom-nav ul li a {
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-size: 14px;
    color: #FFF;
    text-decoration: none;
}

nav#footer-bottom-nav ul li a:hover {
    color: #FFF;
}

/* Headers */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    display: inline-block;
}

h5, .h5 {
    font-size: 32px;
}

.title-wrapper {
    position: relative;
}

.title-wrapper .title {
    position: absolute;
}

.title h1, .title h2, .title h3, .title h4, .title h5, .title h6,
.title .h1, .title .h2, .title .h3, .title .h4, .title .h5, .title .h6 {
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
    border-bottom: 2px solid #E0745A;
}

.title h2, .title .h2 {
    border-bottom: none;
}

.title h5, .title .h5 {
    border-bottom: none;
}

/* Popovers */
.popover {
   max-width: 500px !important;
}

.popover-title {
    font-size: 18px;

}

.popover-content {
    font-weight: normal;

}

/* Flex slider */
.flexslider .slides {
    background: #E3E2E3;
    height: 400px;
}

.flexslider .slides > li {
    padding: 0;
}

.flex-direction-nav a {
    background: #E0745A;
}

.flex-control-paging li a.flex-active {
    background: #E0745A;
}

/* Content */
#content .container {
    padding-top: 22px;
}

.margin-bot {
    margin-bottom: 12px !important;
}

/* Page toolbar */
.page-toolbar {
    position: relative;
}

.page-toolbar div {
    padding-left: 10px;
}

.page-toolbar div:first-child {
    padding-left: 0px;
}

/* Category */
.category {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding-top: 8px;
}

.category-icon {
    text-align: center;
}

.category-icon img.active {
    opacity: 0.6;
}

/* Class status */
.green-circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #627F56;
    margin-top: 5px;
}

.red-circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #FF573D;
    margin-top: 5px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
}

/* Breadcrumbs */
.tbg-breadcrumb {
    float: left;
    width: 400px !important;
}

.tbg-breadcrumb a {
    color: #fff;
    text-decoration: none;
}

.tbg-breadcrumb a:hover {
    text-decoration: underline;
}

.site-breadcrumbs {
    overflow: hidden;
    padding-bottom: 15px;
}

.site-breadcrumbs .tbg-breadcrumb {
    float: left;
    width: inherit !important;
}

.site-breadcrumbs .tbg-breadcrumb a {
    color: #696969;
    text-decoration: none;
}

/* SME Hints */
.text-box ul, .text-box ol {
    padding-left: 40px;
}

/* Game over */
.game-over {
    text-align: center;
    margin: 100px 0;
}

/* Settings page */
.table tr td {
    vertical-align: middle !important;
}

/* jquery.ui.touch-punch MS fix */
.ui-slider-handle{
    -ms-touch-action: none;
    touch-action: none;
}

/* Templates */
.template-details{
    margin-top: 50px;
}

.template-details .control-label {
    text-align: left !important;
}

.template-card {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #E0E0E0;
    padding: 10px;
    height:100%;
    position: relative;
    min-height: 500px;
}

.floating-card {
    width: 32%;
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.template-image {
    -moz-border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    cursor: pointer;
    max-height: 435px;
}

.ui-menu-item {
    font-size: 16px !important;
    font-family: "OswaldLight" !important;
}

#domMessage {
    text-align: center;
}

.video-slide {
    height: auto !important;
}

body.cke_editable {
	padding-top: 0px;
}

.terminology tbody tr td {
    line-height: 38px;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.editable-inline {
    height: 38px !important;
    margin-top: -10px !important;
}

.editable-submit {
    margin-top: 3px !important;
}

.editable-cancel {
    margin-top: 3px !important;
}

.editable-click {
    padding-left: 30px;
    background: url('../images/ico_edit.png') no-repeat 0 1px;
    border-bottom: none !important;
}

.deletable-click {
    padding-left: 16px !important;
    background: url('../images/ico_delete.png') no-repeat 0 1px !important;
    border-bottom: none !important;
}

.subtitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.key-box {
    float: left;
    height: 33px;
    width: 33px;
    border: 1px solid #D3D3D3;
    margin-right: 10px;
    border-radius: 3px;
    padding: 3px 3px;
}

.key-box img {
    cursor: pointer;
    height: 25px;
    width: 25px;
}

.selected-keys {
    overflow: hidden;
    margin-bottom: 40px;
}

.keys-gallery {
    width: 60%;
    margin-bottom: 20px;
    margin-top: 15px;
}

.keys-gallery img {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.level-diamond {
  height: 39px;
  width: 39px;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  background: url('../images/diamond.png') no-repeat 0 1px;
}

img.key {
  height: 25px;
  width: 25px;
  margin: 2px;
}

.key-input {
  text-align: center;
  width: 60px;
  padding-left: 0;
}

.level-key-list li .form-group {
  margin: 0;
  margin-bottom: 15px !important;
}

.level-badge {
  position: relative;
  top: -10px;
  left: -13px;
  background: #E0745A;
  margin-right: -15px;
}

.award-rule input[type="text"], .award-rule select {
    height: 34px;
    margin-bottom: 5px;
    width: 75px;
}

.award-rule .btn {
    display: inline-block;
    padding: 4px 12px 5px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    border-color: #adadad;
    margin-bottom: 5px;
    width: 75px;
}
.award-rule .bootstrap-select {
    width: 75px !important;
}
.award-rule .bootstrap-select .dropdown-toggle {
    min-height: 34px;
}
.award-rule .field-validation-error {
    display: block;
    padding-left: 20px;
}
.award-rule .field-validation-error:last-child {
    margin-bottom: 15px;
}

.glyphicon-none:before {
    content: "\2122";
    color: transparent !important;
}

.manual-rewards-gallery img {
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
}

.manual-rewards img {
    margin-left: 4px;
}

#accordion.panel-levels {
  border-bottom: 0;
}

#accordion.panel-levels .panel+.panel {
  margin-top: 10px;
}

.panel-banner {
  padding: 0;
}

.panel-banner img {
  border-radius: 0 !important;
}

#accordion.panel-levels .inactive {
  width: 80%
}

#accordion.panel-levels .locked {
  float: right;
}

#accordion.panel-levels .locked .glyphicon {
  position: absolute;
  top: 10px;
  left: 8px;
}

#accordion.panel-levels .panel-body {
  background: #fafafa;
}

.mapping .bootstrap-select {
    width: 100px !important;
}

.coowner-button {
    font-size: 24px;
    cursor: pointer;
}

.coowner-item:last-child {
    margin-bottom: 5px;
}

.act-target-group select {
    height: 34px;
    margin-bottom: 5px;
    width: 75px;
}

.act-target-group .btn {
    display: inline-block;
    padding: 4px 12px 5px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: right;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    border-color: #adadad;
    margin-bottom: 5px;
    width: 75px;
}
.act-target-group .multiselect {
    width: 75px !important;
}
.act-target-group .multiselect .dropdown-toggle {
    min-height: 34px;
}

.dropdown-menu a {
    text-decoration: none;
}

.loading {
    background: url('../images/loading.gif');
    width: 32px;
    height: 32px;
    display: block;
}

.select-loader {
    position: absolute;
    right: 15px;
    top: 3px;
}

.table-students-header {
    background: #E0745A !important;
    color: #ffffff !important;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
}

.table-students-row {
    padding-top: 12px !important;
    padding-bottom: 10px !important;
}

.team-remove-btn {
    margin-top: 1px;
}

.striped-row {
    margin: 0;
}

.striped-row > .col-sm-12 {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
}

.team-list {
    min-height: 285px;
    margin-bottom: 30px;
}

.team > .striped-row > .col-sm-12 {
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.striped-row .table-students-row {
    background-color: #F5F5F5;
}

.striped-row .table-students-row:nth-child(even){
  background-color: #fff;
/*rgba(0,0,0,0.04);*/
}

.table-team-header {
    background: #376D81 !important;
    color: #ffffff !important;
    line-height: 20px !important;
    height: 45px;
}

.table-team-code
{
    background: #73A8AD !important;
    color: #ffffff !important;
    font-size: 24px;
}

.editable-container .form-group {
    margin-left: 0;
    margin-right: 0;
}

.x-clearable.btn:hover,
.team-remove-btn.btn:hover {
    background: url('../images/ico_delete.png') no-repeat 0 1px !important;
}

.team-list .table-students-row .checkbox input[type="checkbox"] {
    display: none;
}

.team-list .table-students-row .checkbox label {
    padding-top: 4px;
}

#student-list .x-clearable {
    display: none;
}

.ui-droppable-active .team-list { 
    background: rgba(0,0,0,0.04);
}

#teams-loader .loading {
    float: left;
}

#teams-loader span {
    position: relative; 
    top: 7px;
}