/* Grid */
table.dataTable {
    width: 100%;
    margin: 0 auto;
    margin-top: 0px !important;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
}

table.dataTable tbody tr {
    background-color: white;
}

table.dataTable thead tr {
    background-color: #FAFAFA;
}

table.dataTable {
    margin-bottom: 0 !important;
}

.grid-footer {
    border-top: 1px #DDDDDD solid;
    padding-top: 10px;
}

.dataTables_length {
    float: left;
}

div.dataTables_info {
    float: left;
    padding: 13px 6px 0px 8px;
}

div.dataTables_length {
    padding-top: 5px;
}

div.dataTables_length select {
    height: 37px;
}

div.dataTables_paginate {

}

table.dataTable thead > tr > th {
    padding-left: 7px;
    font-size: 18px;
}

table.dataTable tbody td, table.dataTable thead th {
    white-space: nowrap;
}

.table > tbody > tr > td {
    padding: 8px 8px 6px 8px;
}

table.dataTable.dtr-inline.collapsed tbody td:first-child:before, table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    background-color: #4B4A4B;
    border: medium none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    box-sizing: content-box;
    color: #FFF;
    content: "+";
    display: block;
    height: 16px;
    left: 8px;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 16px;
}

table.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before, table.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before {
    background-color: #E0745A;
    content: "-";
    line-height: 14px;
    padding-left: 1px;
    width: 15px;
}

.dataTables_empty {
    text-align: center;
}

th.actions-1 {
    width: 38px !important;
}

th.actions-2 {
    width: 70px !important;
}

th.actions-3 {
    width: 130px !important;
}

/* Pager */
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #E0745A;
    border-color: #E0745A;
    color: #fff;
    cursor: default;
    z-index: 1;
}

.pagination > li > a, .pagination > li > span {
    color: #6F6E6F;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    color: #E0745A;
}