/* Margin */
.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-15 {
    margin-left: 15px;
}

/* Padding */
.pt-5 {
    padding-top: 5px;
}

.pt-15 {
    padding-top: 15px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-0
{
    padding-bottom: 0px;
}

/* Width */
.w-5 {
    width: 5px !important;
}

.w-10 {
    width: 10px !important;
}

.w-20 {
    width: 20px !important;
}

.w-30 {
    width: 30px !important;
}

.w-40 {
    width: 40px !important;
}

.w-50 {
    width: 50px !important;
}

.w-150 {
    width: 150px !important;
}

.w-250 {
    width: 250px !important;
}

/* Inline-block */
.ib {
    display: inline-block;
}

/* Text */
.text-weight-normal {
    font-weight: normal;
}

.text {
    padding-top: 8px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-auto {
    cursor: auto !important;
}

.right {
    text-align: right !important;
}