.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
    background-image: url("http://tbg.evercode/assets/jquery/images/ui-icons_222222_256x240.png");
    color: #FFF;
}

.ui-widget-header {
    background: none repeat scroll 0 0 #E1745A;
    border-color: #E1745A;
    border-radius: 3px;
    color: #fff;
    font-family: "OswaldRegular","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-size: 18px;
    text-decoration: none;
    text-transform: none;
    font-weight: normal;
}

.ui-datepicker-calendar {
    font-family: "OswaldRegular","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-weight: normal;
}

.ui-datepicker th {
    font-weight: normal;
    color: #4A494A;
}

.ui-state-default, .ui-widget-content .ui-state-default {
    color: #6F6E6F;
    border-radius: 3px;
}

.ui-widget-header .ui-state-default {
    border: 0;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    height: 1.8em;
    position: absolute;
    top: 3px;
    width: 1.8em;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus {
    background: none repeat scroll 0 0 #E1745A;
    color: #FFF;
    border-color: #E1745A;
    border-radius: 3px;
}

.ui-widget-header .ui-state-hover, .ui-widget-header .ui-state-focus {
    border: 0;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    background: none repeat scroll 0 0 #E1745A;
    color: #FFF;
    border-color: #E1745A;
    border-radius: 3px;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    background: none repeat scroll 0 0 #6F6E6F;
    color: #FFF;
    border-color: #6F6E6F;
    border-radius: 3px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-next {
    right: 1px;
    cursor: pointer;
}