.layout-modules.configuration {

}

.layout-modules.configuration .modules .module {
    margin-bottom: 20px;
}

.layout-modules.configuration .no-modules {
    margin-bottom: 20px;
}

.layout-modules.configuration .module-header {
    position: relative;
}

.layout-modules.configuration .module-header .title {
    background: #4b4a4b;
    color: #FFFFFF;
    border-radius: 4px;
    display: inline-block;
    line-height: 1.42857;
    vertical-align: middle;
    white-space: nowrap;
    padding: 4px 9px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
    min-width: 100px;
    width: 41%;
}

.layout-modules.configuration .module-header .actions {
    position: absolute;
    margin-left: 3px;
    width: 120px;
}

.layout-modules.configuration .module-header .actions .action {
    background: #4b4a4b;
    color: #FFFFFF;
    border-radius: 4px;
    display: inline-block;
    line-height: 1.42857;
    vertical-align: middle;
    white-space: nowrap;
    padding: 4px 9px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
}

.layout-modules.configuration .module-header .actions .action:hover {
    background: #E0745A;
}

.layout-modules.configuration .module-body {
    background: #fbfbfb;
    padding: 10px;
}

.layout-modules.configuration .module-body .info {
    padding-bottom: 10px;
}

.layout-modules.configuration .module-body .file {
    width: 50%;
}

.layout-module-placeholder {
    border: 1px dotted black;
    margin: 0 0 1em 0;
    height: 100px;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}