#content img {
    border-bottom: 0 !important;
}

/* Links */
a {
    text-decoration: underline;
}

a:hover, a:active, a:focus {
    text-decoration: underline;
}

a, button {
    font-weight: normal !important;
}

.button-icon {
    height: 16px;
    display: inline-block !important;
    vertical-align: baseline;
    margin-right: 5px !important;
}

a.link-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    cursor: default;
}

a.link-button:hover {
    background: #4b4a4b !important;
}

input.link-button {
    background: #FFFFFF;
    width: 600px;
    line-height: 1.42857;
    padding: 5px 12px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

a.play-button {
    display: inline-block;
    height: 32px;
    width: 32px;
}

/* Alerts */
.alert {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    background-color: #e1b7ad !important;
    color: #613227 !important;
    margin-bottom: 10px;
}

.alert-danger {
    background-color: #e1b7ad !important;
    color: #613227 !important;
}

.alert-success {
    background-color: #e4e3e4 !important;
    color: #4b4a4b !important;
}

.alert-info {
    background-color: #e4e3e4 !important;
    color: #4b4a4b !important;
}

/* Inputs */
input {
    color: #000000;
    width: auto;
}

input[type=text] {
    font-size: 18px;
}

.input-group input {
    line-height: 24px;
    padding: 7px 0 5px 10px;
}

.input-sm, .form-horizontal .form-group-sm .form-control {
    font-size: 14px;
    line-height: 1.6;
}

.input-error {
    background: none repeat scroll 0 0 #e1b7ad !important;
    color: #613227 !important;
}

.input-group {
    margin-bottom: 0px;
}

.input-group-error input, .input-group-error iframe, .input-group-error textarea, .input-group-error span.input-group-addon {
    background: none repeat scroll 0 0 #e1b7ad !important;
    color: #613227 !important;
}

.has-error .input-group-addon, .has-error input {
    border-color: #DF7A73;
}

input:required {
    box-shadow:none;
}

.search-input {
    width: 200px;
}

.datepicker {
    width: 85px !important;
}

.input-group-addon.datepicker-addon {
    width: auto;
}

.input-round input {
    padding-right: 10px;
    border-radius: 3px !important;
}

/* Textarea */
textarea {
    font-size:  18px;
    background: #EDEDED !important;
}

.form-control {
    font-size: 18px;
    height: 37px;
}

/* Radio, checkbox */
.form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
    padding-top: 8px !important;
}
.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
    min-height: 20px;
}

div.remove-file {
    margin-top: 2px;
}

input[type=checkbox].remove-file {
    width: auto !important;
    margin: 0px 8px 0px 0px;
}

label.remove-file {
    cursor: pointer;
    font-weight: normal;
}

.btn-file {
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    padding: 5px 12px 6px 12px !important;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 18px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: #4A494A;
    cursor: inherit;
    display: block;
}
input[readonly] {
    cursor: text !important;
}

/* Buttons */
.btn {
    background: #4B4A4B;
    text-decoration: none;
    font-family: "OswaldLight","OpenSansSemibold",Arial,Helvetica,sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-transform: none;
    padding: 5px 12px 5px 12px;
    color: #FFF;
    border: 0;
    margin-bottom: 3px;
}

.btn:hover, .btn:focus, .btn:active, .btn.active {
    background: #E1745A !important;
    color: #FFF;
}

.btn.disabled, .btn[disabled] {
    background: #929192 !important;
}

.btn-default, .btn-group-default > .btn {
    font-size: 16px;
    padding: 3px 7px 3px 7px;
}

.btn-xs, .btn-group-xs > .btn {
    padding: 3px 6px 2px 6px;
    font-size: 14px;
}

.btn-sm, .btn-group-sm > .btn {
    font-size: 16px;
    padding: 3px 7px 3px 7px;
}

.modal-footer .btn + .btn {
    margin-bottom: 3px;
}

.close {
    font-size: 34px;
    line-height: 0.4;
}

.field-validation-error {
    color: #613227 !important;
    display: inline-block;
    padding-top: 2px;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline {
    color: #613227 !important;
}

.has-error input {
    color: #a94442;
    background-color: #f2dede;
}

.btn-lg, .btn-group-lg > .btn {
    font-size: 20px;
    padding: 10px 16px;
}

/* Select */
select {
    font-size: 18px;
}

select.form-control {
    padding: 3px 12px 5px 12px;
}



/* Tabs */
.tab-content {
    background: #FAFAFA;
    border-top: 1px solid #6F6E6F;
    -webkit-border-radius:	0px 0px 3px 3px;
    -moz-border-radius:	0px 0px 3px 3px;
    border-radius:	0px 0px 3px 3px;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs i {
    font-size: 13px !important;
    padding: 4px 2px;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs > li > a {
    margin-right: 2px;
    border-top: 2px solid #6F6E6F;
    border-right: 1px solid #6F6E6F;
    border-left: 1px solid #6F6E6F;
    -webkit-border-radius:	3px 3px 0px 0px;
    -moz-border-radius:	3px 3px 0px 0px;
    border-radius:	3px 3px 0px 0px;
    line-height: 1.68;
    text-decoration: none;
    background-color: #E1745A;
    color: #FFFFFF;
    font-weight: bold !important;
    font-size: 16px !important;
}

.nav-tabs > li > a:hover {
    border-color: #6F6E6F;
    color: #555555;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    cursor: default;
    background-color: #FAFAFA;
    border-top: 2px solid #6F6E6F;
    border-right: 1px solid #6F6E6F;
    border-bottom: 1px solid #FAFAFA;
    border-left: 1px solid #6F6E6F;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.tab-pane {
    padding: 15px;
}

.tab-pane p {
    margin: 0;
}

.tabs-right {
    position: absolute;
    right: 0px;
    top: 13px;
}

.tabs-right .nav {
    float: right;
}

.tab-pane {
    padding: 15px 0px 0px 0px;
}

/* Wysihtml5 */
ul.wysihtml5-toolbar > li {
    margin: 0 5px 5px 0 !important;
}

.wysihtml5-sandbox {
    background: #EDEDED !important;
}

/* Progress bar */
.progress {
    background: none repeat scroll 0 0 #fff;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    height: 36px !important;
    margin-bottom: 10px;
}

.progress-bar {
    background-color: #E0745A !important;
    font-size: 18px !important;
    padding: 8px 10px;
    text-align: left;
}

/* Other */
#answer-timer-wrapper {
    display: none;
}

#timer-wrapper {
    display: none;
}

.blink {
    background: #6F6E6F !important;
}

#classroom-timer-wrapper {
    display: none;
}

/* Categories */
.categories .instructions {
    margin-top: 50px;
}

.category {
    font-weight: normal;
}

.sortable-categories .sort-item {
    border: 1px #DDDDDD solid;
    background: #F5F5F5;
    padding: 4px;
    height: 50px;
}

.sortable-categories .name {
    width: 150px;
    display: inline-block;
    position: static;
}

.sortable-categories .icon {
    width: 50px;
    display: inline-block;
    position: static;
}

/* Answer */
.question-wrapper {
    font-size: 18px;
}

.answer-timer {
    margin-top: 3px;
}

.selected-answer {
    background: #6F6E6F;
    color: #FFF;
    padding: 5px 0px 5px 0px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    border-radius: 3px;
}

.question-row, .answer-row {
    min-height: 80px;
    padding: 10px 0px 10px 0px;
}

.answer-row-large {
    min-height: 170px;
}

/* Sortable */
.sortable {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: auto;
}

.sortable li {
    margin: 0 3px 3px 3px;
}

.sortable li span {
    position: absolute;
}

.sortable-slides {
    width: 458px;
}
.sortable-slides li {
    height: 152px;
}

/* Sliders UI */
.slider.ui-widget-content {
    background: #000000;
    border: 1px #000000 solid;
    border-radius: 25px;
    height: 14px;
}

.slider.ui-slider-horizontal .ui-slider-handle {
    margin-left: -1.1em;
    top: -0.92em;
    line-height: 45px !important;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
}

.slider.ui-state-default,
.slider.ui-widget-content .ui-state-default,
.slider.ui-widget-header .ui-state-default {
    /*background: #e9896d;*/
    background: #da6a32;
    border: 1px #e9896d solid;
}

.slider-2 .slider.ui-state-default,
.slider-2 .slider.ui-widget-content .ui-state-default,
.slider-2 .slider.ui-widget-header .ui-state-default {
    background: #5c5d5e;
    border: 1px #5c5d5e solid;
}

/* Grade2 slider */
.grade2-item-container .slider{
    margin-right: 20px;
}

.grade2-item-container .slider-scale-desc{
    color: #000000;
}
.grade2-item-container .slider-scale-desc .pull-right{
    margin-right: 20px;
}
.grade2-item-container .title{
    font-size: 20px;
    font-weight: bold;
}
.slider-1 .title{
    /*color: #e9896d;*/
    color: #da6a32;
}
.slider-2 .title{
    color: #5c5d5e;
}

/* Grade2 table */
.table-grade2{
    color: black;
    font-weight: bold;
}
.table-grade2 .grade1,
.table-grade2 .grade1 input{
    /*color: #e9896d;*/
    color: #da6a32;
}
.table-grade2 .grade2,
.table-grade2 .grade2 input{
    color: #5c5d5e;
}
.table-grade2 input{
    width: 35px;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
}
/* END - Grade2 table */

.row-header-wrapper {
    position: relative;
}

.row-header-wrapper div {
    position: absolute;
    width: 30px;
    top: -20px;
    left: 555px;
}

.layout-modules-container {
    background: #FFFFFF;
    padding: 10px;
}

.alternatives-row {
    background: #F6F6F6;
    border: 1px #807F80 solid;
    margin-top: 15px;
    padding: 8px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alternatives-row.checked {
    background: #D3D077;
    border: 1px #D3D077 solid;
}

.alternatives-row:first-child {
    margin-top: 0px;
}

.alternatives-image {
    width: 150px;
    padding-right: 15px;
    vertical-align: top;
}

.alternatives-content {
    vertical-align: top;
    width: 99%;
}

.alternatives-title {
    font-weight: bold;
    margin-bottom: 3px;
}

.alternatives-description {

}

.alternatives-result tr td {
    margin-top: 15px;
    padding: 8px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alternatives-row-result:first-child {
    margin-top: 0px;
}

.alternatives-points {
    text-align: right;
}

.alternatives-points div {
    display: inline-block;
    width: 44px;
}

.alternatives-points.checked div {
    background: #6E6D6E;
    color: #FFF;
    font-weight: bold;
}

.alternatives-points div {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    padding: 18px 0px 18px 0px;
}

.alternatives-points-sum div {
    background: url('/images/sigma.png') no-repeat;
    width: 63px;
    height: 36px;
    font-size: 38px;
    font-weight: bold;
    color: #000000;
    text-align: right;
    padding: 12px 14px 0px 0px;
}

/* Evaluation */
.evaluation-text {
    font-size: 22px;
    line-height: 1.1;
}

.title-points {
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
    width: 150px;
    font-size: 20px;
}

.input-points input {
    padding: 53px;
    font-size: 30px;
    width: 150px;
    height: 150px;
    text-align: center;
}

.list-evaluation {
    margin-left: 0;
    padding-left: 24px;
}

.list-evaluation li {
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 1.1;
    color: #ABA749;
}

.list-evaluation li span {
    color: #696970;
}

.remove-row {
    padding-top: 3px;
    padding-left: 0px;
}

.answer-points {
    text-align: center;
    font-size: 22px !important;
    font-weight: bold;
    width: 70px;
    padding: 6px 10px 6px 10px;
}

.list1-points-sum {
    background: url('/images/sigma.png') no-repeat 0px -3px;
    width: 70px;
    height: 36px;
    font-size: 27px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    padding: 12px 0px 0px 30px;
}

.list1-points-sum div {
    width: 70px;
}

.list1-result tr td {
    padding-bottom: 16px;
}

.list1-row-point {
    text-align: right;
}

.list1-row-point div {
    background: #6E6D6E;
    color: #FFF;
    font-weight: bold;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    padding: 18px 0px 18px 0px;
    width: 44px;
    display: inline-block;
}

/* Feedback */
.input-points div {
    padding: 35px;
    font-size: 40px;
    width: 150px;
    text-align: center;
    font-weight: bold;
}

table.points-chart-table {
    width: 100%;
}

table.points-chart-table td.points-title {
    font-weight: bold;
    vertical-align: middle;
    font-size: 20px;
}

table.points-chart-table tr.top td.points-title {
    padding: 8px 0px 4px 0px;
}

table.points-chart-table tr.bottom td.points-title {
    padding: 4px 0px 8px 0px;
}

table.points-chart-table tr.top td.points-content {
    padding: 8px 0px 4px 0px;
    border-left: 2px #6F6E6F solid;
}

table.points-chart-table tr.bottom td.points-content {
    padding: 4px 0px 8px 0px;
    border-left: 2px #6F6E6F solid;
}

.points-chart {
    width: 100%;
    height: 36px;
}

.points-chart div.value {
    width: 20%;
    float: left;
    font-weight: bold;
    font-size: 20px;
    padding: 7px 8px 0px 8px;
}

.points-chart div.bar-wrapper {
    width: 80%;
}

.points-chart div.bar-wrapper div.bar {
    height: 36px;
    float: left;
}

.points-chart div.bar-wrapper div.bar-light-green {
    background: url('/images/rectangle_light_green.png') repeat-x;
}

.points-chart div.bar-wrapper div.bar-dark-green {
    background: url('/images/rectangle_dark_green.png') repeat-x;
}

.points-chart div.bar-wrapper div.bar-light-gray {
    background: url('/images/rectangle_light_gray.png') repeat-x;
}

.points-chart div.bar-wrapper div.bar-dark-gray {
    background: url('/images/rectangle_dark_gray.png') repeat-x;
}

/* Export answers */
.select-classes {
    height: 350px !important;
}